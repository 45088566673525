<template>

    <div class="organizer-dashboard">

        <organizer-dashboard-navigation v-if="$store.state.auth.user.is_organizer"/>

        <organizer-artistic-applications-list v-if="$store.state.auth.user.is_organizer" />

        <create-organize-profile-form v-else>
            <template #title>
              {{ $t("Please fill out the Organizer's profile to be able to create vacancies") }}
            </template>
        </create-organize-profile-form>

    </div>
</template>

<script>
    import OrganizerArtisticApplicationsList from "@/components/Organizer/ArtisticApplications/List";
    import OrganizerDashboardNavigation from "@/components/Organizer/DashboardNavigation";
    import CreateOrganizeProfileForm from "@/components/Forms/CreateOrganizerProfileForm";

    export default {
        name: "OrganizerDashboard",
        title(){ return this.$t('Organizer dashboard');},
        components: {OrganizerDashboardNavigation, OrganizerArtisticApplicationsList, CreateOrganizeProfileForm},
    };
</script>

<style scoped>

</style>