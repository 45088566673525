<template>
    <div class="columns">
        <div class="column has-text-left">
            <div class="buttons">
                <b-button tag="router-link"
                          size="is-small"
                          :to="{name: 'organizerDashboard'}"
                          :type="{'is-primary': routeNameIs('organizerDashboard')}">
                    {{$t('Applications')}}
                </b-button>
                <b-button tag="router-link"
                          size="is-small"
                          :to="{name: 'organizerVacancies'}"
                          :type="{'is-primary': routeNameIs('organizerVacancies')}">
                    {{$t('Vacancies')}} <b-tag rounded class="ml-1">{{ vacanciesCount }}</b-tag>
                </b-button>
                <b-button tag="router-link"
                          size="is-small"
                          :to="{name: 'organizerArtisticProfilesCollection'}"
                          :type="{'is-primary': routeNameIs('organizerArtisticProfilesCollection')}">
                    {{$t('Favorite projects')}}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import {GET_ARTISTIC_VACANCIES_COUNT} from "@/store/modules/artisticVacancies/actions-types";

export default {
    name: "OrganizerDashboardNavigation",
    methods: {
        ...mapActions('artisticVacancies', [
            GET_ARTISTIC_VACANCIES_COUNT,
        ]),
        routeNameIs(routeName){
            return routeName === this.$route.name;
        },
    },
    created(){
        this[GET_ARTISTIC_VACANCIES_COUNT]();
    },
    computed: {
        vacanciesCount(){
            return this.$store.state.artisticVacancies.vacanciesCount;
        },
    },
};
</script>

<style scoped>

</style>