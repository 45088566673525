<template>
    <div v-if="artisticVacancies && artisticVacancies.length">
        <!--TODO dont apply dynamical application status change if filter is apply-->
        <b-field >
            <b-select v-model="forVacancy" size="is-small">
                <option :value="null">{{ $t('all vacancies')}}</option>
                <option v-for="artisticVacancy in artisticVacancies"
                        :value="artisticVacancy.id"
                        :key="'artistic-vacancy-' + artisticVacancy.id">
                    {{artisticVacancy.title}}
                </option>
            </b-select>
        </b-field>
        <b-field>
            <b-switch v-model="status" true-value="rejected" false-value="sent,accepted" size="is-small">{{ $t('show rejected applications')}}</b-switch>
        </b-field>
    </div>
</template>

<script>
    import {
        SET_FILTER_FOR_VACANCY,
        SET_FILTER_STATUS
    } from "@/store/modules/artisticApplications/mutation-types";
    import {mapActions, mapMutations} from "vuex";
    import {GET_ARTISTIC_VACANCIES} from "@/store/modules/artisticVacancies/actions-types";

    export default {
        computed: {
            forVacancy: {
                get(){
                    return this.$store.state.artisticApplications.filter.forVacancy;
                },
                set(value){
                    this[SET_FILTER_FOR_VACANCY]( value );
                },
            },
            status: {
                get(){
                    return this.$store.state.artisticApplications.filter.status;
                },
                set(value){
                    this[SET_FILTER_STATUS]( value );
                },
            },
            artisticVacancies(){
                return this.$store.state.artisticVacancies.vacancies.filter(f => f.published);
            },
        },
        methods:{
            ...mapMutations('artisticApplications', [
                SET_FILTER_FOR_VACANCY,
                SET_FILTER_STATUS,
            ]),
            ...mapActions('artisticVacancies', [
                GET_ARTISTIC_VACANCIES,
            ]),
        },
        mounted() {
            this[GET_ARTISTIC_VACANCIES]();
        },
        name: "OrganizerArtisticApplicationsListFilter"
    };
</script>

<style scoped>

</style>