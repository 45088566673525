<template>
    <div>
        <div class="block">

            <organizer-artistic-applications-list-filter />
        </div>

        <div class="has-text-centered  mt-7" v-if="!artisticApplications || !artisticApplications.length">
            <h4 v-if="'rejected' === this.filter.status" class="title is-4 has-text-grey-dark">{{$t("There are no rejected applications yet")}}</h4>
            <h4 v-else class="title is-4 has-text-grey-dark">{{$t("There are no suitable applications yet, but don't worry! We will send you a notification as soon as something appears")}}</h4>
        </div>

        <!--RELEVANT APPLICATIONS LIST-->
        <organizer-artistic-applications-list-item
                v-for="(application, index) in artisticApplications"
                :key="'application-' + application.id"
                :application="application"
                :index="index"/>

        <laravel-pagination :pagination-meta="paginationMeta" @change="getData" />


    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import OrganizerArtisticApplicationsListItem from "./ListItem";
    import OrganizerArtisticApplicationsListFilter from "./ListFilter";
    import {GET_ORGANIZER_ARTISTIC_APPLICATIONS} from "@/store/modules/artisticApplications/actions-types";
    import formHelperMixin from "@/mixins/formHelperMixin";
    import LaravelPagination from "@/components/LaravelPagination";

    export default {
        components: {OrganizerArtisticApplicationsListItem, OrganizerArtisticApplicationsListFilter, LaravelPagination},
        data: function () {
            return {
                showRejectedApplications: false,
                paginationMeta: {},
            };
        },
        methods: {
            ...mapActions('artisticApplications',[
                GET_ORGANIZER_ARTISTIC_APPLICATIONS,
            ]),
            getData(page = 1){
              this.$Progress.start();
              this[GET_ORGANIZER_ARTISTIC_APPLICATIONS]({
                'filter[status]': this.filter.status,
                'filter[for_vacancies]': this.filter.forVacancy,
                page,
              }).then((res) => {
                this.paginationMeta = res.data.meta;
                this.$Progress.finish();
              });
            }
        },
        computed: {
            ...mapState({
                'artisticApplications': state => state.artisticApplications.organizerApplications,
            }),
            filter(){
                return this.$store.state.artisticApplications.filter;
            },
        },
        mounted() {
            this.getData();
        },
        watch: {
            'filter.forVacancy': function(){
                this.getData();
            },
            'filter.status': function () {
                this.getData();
            },
        },
        mixins: [formHelperMixin],
        name: "OrganizerArtisticApplicationsList"
    };
</script>

<style scoped>

</style>