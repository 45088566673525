<template>
<div class="artistic-application-list-item-gallery">
    <b-button v-if="!asGallery"
              @click="switchGalleryView(true)"
              icon-left="expand-arrows-alt"
              size="to-gallery-switcher"
              class="is-light"/>
    <b-carousel
        :progress="true"
        :autoplay="false"
        :arrow-hover="false"
        :indicator="false"
        :overlay="asGallery"
        icon-size="is-medium"
        @change="handleSlideChanged($event)"
        progress-type="is-primary">


        <b-carousel-item v-for="image in images" :key="image.uuid">
            <b-image :draggable="false"
                     class="has-text-centered is-fullheight"
                     :responsive="!asGallery"
                     :src="image.original_url"
                     :title="image.name"
                     :ratio="asGallery ? '' : '4by3'"></b-image>
        </b-carousel-item>
        <b-carousel-item v-if="primePromoLink">
            <div class="iframe-container">
                <iframe
                    v-if="currentSlideIndex === Object.keys(images).length"
                    :src="primePromoLink | getYoutubeEmbedLink"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                    allowfullscreen>
                </iframe>
            </div>
        </b-carousel-item>

        <span v-if="asGallery" @click="switchGalleryView(false)" class="modal-close is-large has-background-grey-light"></span>
    </b-carousel>
</div>
</template>

<script>
    import imgUrlGenerator from "@/mixins/imgUrlGenerator";
    export default {
        name: "OrganizerArtisticApplicationsListItemGallery",
        props: {
            images: {
                type: [Object,Array],
                default: () => {return {};},
            },
            primePromoLink: {
                type: String,
                default: null,
            },
            profileId: Number,
        },
        data: () => ({
            isGalleryModalActive: false,
            currentSlideIndex: 0,
            asGallery: false,
        }),
        methods: {
            handleSlideChanged(slideIndex){
                this.currentSlideIndex = slideIndex;
            },
            switchGalleryView(asGallery){
                this.asGallery = asGallery;
                if (asGallery) {
                    return document.documentElement.classList.add('is-clipped');
                } else {
                    return document.documentElement.classList.remove('is-clipped');
                }
            }
        },
        mixins: [imgUrlGenerator],
    };
</script>

<style scoped lang="scss">
    .iframe-container{
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .to-gallery-switcher {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
    }
    .is-fullheight {
        height: 100% !important;
    }
    ::v-deep {
        .carousel-item{
            height: 100%;
        }
        .is-fullheight{
            height: 100%;
            img {
                height: 100% !important;
            }
        }
    }

</style>