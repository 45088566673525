<template>
    <b-pagination
        :key="renderKey"
        v-if="paginationMeta.total"
        class="my-5"
        @change="$emit('change', currentPage)"
        :total="paginationMeta.total"
        v-model="currentPage"
        :per-page="paginationMeta.per_page"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">
    </b-pagination>
</template>

<script>
export default {
    name: "LaravelPagination",
    props: ['paginationMeta'],
    data(){
        return {
            currentPage: null,
            renderKey: 1,
        };
    },
    created() {
        this.currentPage = this.paginationMeta.current_page;
    },
    watch: {
        'paginationMeta.current_page': function(to, from) {
            if (to!==from){
                this.currentPage = to;
            }
        }
    },
};
</script>

<style scoped>

</style>