<template>
    <div class="card-thread-chat">
        <div class="thread-messages mb-3" style="overflow: auto; max-height: 300px" ref="threadContainer">

            <div v-if="!messages.length" class="has-text-centered py-3">{{ $t('No messages')}}</div>
            <div v-else>
              <div class="has-text-centered">
                <span class="tag tag-light is-clickable" v-if="existsUnloadedMessages" @click="loadNextMessagesPage">{{ $t('view more') }}</span>
              </div>
                <article class="media" v-for="(message, messageIndex) in sortedMessages" :key="`message-${messageIndex}`">
                    <div class="media-content pr-2" :class="message.is_sender ? 'has-text-right' : 'has-text-left'" style="white-space: pre-line">
                        {{ message.body }}
                    </div>
                </article>
            </div>
        </div>

        <div class="field has-addons chat-input" v-if="'rejected' !== application.status">
            <div class="control is-expanded">
                <textarea v-model="form.message.body"
                          ref="chatInput"
                          class="textarea has-fixed-size"
                          @input="chatInputResize"
                          @keydown.enter.exact.prevent="handleMessageSend"
                          @keyup.enter.shift.exact.prevent="form.message.body + '\n'"
                          rows="1" ></textarea>
            </div>
            <div class="control">
                <a class="button is-info" @click="handleMessageSend">
                    <b-icon icon="paper-plane" ></b-icon>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import {
    CREATE_PRIVATE_DIRECT_CHAT,
    GET_CONVERSATION_MESSAGES, MARK_CONVERSATION_AS_READ,
    SEND_PRIVAT_MESSAGE
} from "@/store/modules/chat/action-types";
import Form from "vform";
import {SET_APPLICATION_CONVERSATION} from "@/store/modules/artisticApplications/actions-types";
import {SET_ARTISTIC_APPLICATION_ATTRIBUTE} from "@/store/modules/artisticApplications/mutation-types";

export default {
    name: "ChatThread",
    props: {
        application: {
            type: Object,
        },
    },
    data(){
        return {
            form: new Form({
                participant_id: null,
                participant_type: null,
                message: {
                    body: '',
                },
            }),
            pagination: {
              current_page: null,
              last_page: null,
            },
            messages: [],
        };
    },
    methods:{
        ...mapActions('chat', [
            CREATE_PRIVATE_DIRECT_CHAT,
            SEND_PRIVAT_MESSAGE,
            GET_CONVERSATION_MESSAGES,
            MARK_CONVERSATION_AS_READ,
        ]),
        ...mapMutations('artisticApplications', [
            SET_APPLICATION_CONVERSATION,
            SET_ARTISTIC_APPLICATION_ATTRIBUTE,
        ]),
      loadNextMessagesPage(){
        this.loadMessages(++this.pagination.current_page);
      },
      handleMessageSend(){
        if (!this.form.message.body) return;
        if (!this.application.conversation) return;
        this.messages.unshift({
            body: this.form.message.body,
            is_sender: 1,
        });

        if (this.$store.state.auth.user.id === this.application.user.id){
            this.form.participant_type = this.participantsTypes.application;
            this.form.participant_id = this.application.id;
        } else {
            this.form.participant_type = this.participantsTypes.organizer;
            this.form.participant_id = this.application.vacancy.organizer_profile_id;
        }

        this[SEND_PRIVAT_MESSAGE]({
            conversationId: this.application.conversation.id,
            form: this.form,
        });

        this.form.reset();

        this.$nextTick(() => {
            this.scrollToLastMessage();
        });
        this.chatInputSizeReset();
      },
      scrollToLastMessage(){
          this.$refs.threadContainer.scrollTop = this.$refs.threadContainer.scrollHeight;
      },
      chatInputResize() {
          this.$refs.chatInput.style.height = "5px";
          this.$refs.chatInput.style.height = (this.$refs.chatInput.scrollHeight)+"px";
      },
      chatInputSizeReset(){
          this.$refs.chatInput.style.height = "5px";
      },
      loadMessages(page = 1){
        this[GET_CONVERSATION_MESSAGES]({
          conversation_id: this.application.conversation.id,
          participant_id: this.application.user.id === this.user.id ? this.application.id : this.user.organizer_profile.id,
          participant_type: this.application.user.id === this.user.id ? this.participantsTypes.application : this.participantsTypes.organizer,
          page,
        })
          .then(res => {

            if (page > 1){
              this.messages = this.messages.concat(res.data.data);
            } else {
              this.messages = res.data.data;
              this.$nextTick(this.scrollToLastMessage);
            }

            this.pagination.current_page = res.data.current_page;
            this.pagination.last_page = res.data.last_page;
          });
      },
      checkConversationExists(){
        if (!this.application.conversation){
            this[CREATE_PRIVATE_DIRECT_CHAT]({
                participants: [
                    {
                        id: this.application.id,
                        type: this.participantsTypes.application
                    },
                    {
                        id: this.application.vacancy.organizer_profile_id ,
                        type: this.participantsTypes.organizer,
                    },
                ],
                artistic_application_id: this.application.id,
            })
                .then(res => {
                    this[SET_APPLICATION_CONVERSATION]({
                        applicationId: this.application.id,
                        conversation: res.data,
                    });

                    this.subscribeToConversation();
                });
        } else {
          this.loadMessages();
        }
      },
      subscribeToConversation(){
          if (this.application.conversation){
            this.$echo.private(`mc-chat-conversation.${this.application.conversation.id}`).listen('.Musonza\\Chat\\Eventing\\MessageWasSent', (payload) => {
              if (payload.message.sender.user_id !== this.user.id){
                this.messages.unshift(payload.message);
                this.$nextTick(() => {
                  this.scrollToLastMessage();
                  this.markAsRead();
                });
              }
            });
          }
      },
      markAsRead(){
            if (!this.application.conversation) return;
            this[MARK_CONVERSATION_AS_READ]({
                conversationId: this.application.conversation.id,
                ...this.currentParticipantData
            }).then(() => {
                this[SET_ARTISTIC_APPLICATION_ATTRIBUTE]({
                    applicationId: this.application.id,
                    key: 'conversation_unread_messages',
                    value: 0,
                    organizer: this.application.user.id !== this.user.id,
                });
            });
      },
    },
    computed: {
      ...mapState('chat', ['participantsTypes']),
      user(){
          return this.$store.state.auth.user;
      },
      sortedMessages(){
          return [...this.messages].reverse();
      },
      existsUnloadedMessages(){
        return this.pagination.current_page && this.pagination.last_page
          ? this.pagination.current_page < this.pagination.last_page
          : false;
      },
      currentParticipantData(){
          return this.user.id === this.application.user.id
            ? {participant_id: this.application.id, participant_type: this.participantsTypes.application}
            : {participant_id: this.user.organizer_profile.id, participant_type: this.participantsTypes.organizer};
      },
    },
    mounted() {
        this.scrollToLastMessage();
        this.subscribeToConversation();
        this.markAsRead();
    },
    created() {
        this.checkConversationExists();
    }
};
</script>

<style lang="scss" scoped>
    .card-thread-chat {
        min-height: 200px;
        display:flex;
        flex-direction:column;
        justify-content:space-between;

        .thread-messages::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(187, 187, 187, 0.3);
            background-color: #F5F5F5;
        }

        .thread-messages::-webkit-scrollbar
        {
            width: 10px;
            background-color: #F5F5F5;
        }

        .thread-messages::-webkit-scrollbar-thumb
        {
            background-color: rgba(187, 187, 187, 0.3);
            border: 2px solid rgba(196, 196, 196, 0.3);
        }

        .chat-input{
            margin-top: auto;
            .textarea {
                resize: none;
                //overflow: hidden;
                min-height: 50px;
                max-height: 120px;
            }
            .textarea::-webkit-scrollbar-track
            {
                -webkit-box-shadow: inset 0 0 6px rgba(187, 187, 187, 0.3);
                background-color: #F5F5F5;
            }

            .textarea::-webkit-scrollbar
            {
                width: 10px;
                background-color: #F5F5F5;
            }

            .textarea::-webkit-scrollbar-thumb
            {
                background-color: rgba(187, 187, 187, 0.3);
                border: 2px solid rgba(196, 196, 196, 0.3);
            }

        }
    }
</style>