<template>
    <div class="card mb-4">
        <div class="card-image" v-if="artisticProfile.images && Object.keys(artisticProfile.images).length || artisticProfile.prime_promo_link">
            <!--GALLERY-->
            <organizer-artistic-applications-list-item-gallery
                :prime-promo-link="artisticProfile.prime_promo_link"
                :profile-id="artisticProfile.id"
                :images="artisticProfile.images"/>
        </div>

        <div class="card-header">
            <!--CANDIDATE PROFILE NAME-->
            <div class="card-header-title">
                {{artisticProfile.title}}
            </div>

            <!-- Open chat -->
            <span @click="chatActive = !chatActive"
                  class="card-header-icon"
                  :class="chatActive ? 'has-text-primary' : ''">
                <b-icon icon="envelope"></b-icon>
                <b-tag type="is-danger" rounded v-if="application && application.conversation_unread_messages">
                    {{application.conversation_unread_messages}}
                </b-tag>
            </span>

            <!-- MARK AS FAVORITE -->
            <ProfileFavoriteCollectionToggle :profile="artisticProfile" :application="application"/>

        </div>

        <div class="card-content px-2">
            <template v-if="!chatActive">

                <div class="block" v-if="application && application.vacancy">
                    <span class="heading mb-3">{{$t('for vacancy')}}</span>
                    {{application.vacancy.title}}
                </div>

                <!--STATUS-->
                <div class="block" v-if="'sent' !== application.status" >
                    <b-tag :type="types.application_status_rejected === application.status ?  'is-warning' : 'is-success'" >{{ $t(application.status) }}</b-tag>
                </div>

                <!--ARTIST PROFILE CONTACTS, IF APPLICATIONS ACCEPTED-->
                <article class="message is-primary"  v-if="types.application_status_accepted === application.status">
                    <div class="message-header">
                        <p>
                            {{$t('application accepted')}}
                        </p>
                    </div>
                    <div class="message-body px-2">
                        <div class="content">
                            {{$t('Application has been accepted by you')}}<br>
                        </div>

                        <p class="content" v-text="application.artistic_profile.contacts"></p>

                        <p class="content">
                            <b-button type="is-secondary" @click="chatActive = !chatActive" size="is-small"><b-icon class="mr-1" icon="envelope"></b-icon> {{$t('send message')}} </b-button>
                        </p>

                    </div>
                </article>

                <!--TYPE-->
                <div class="block" v-if="application.artistic_profile.type">
                    <span class="heading mb-3">{{$t('profile type')}}</span>
                    {{$t(application.artistic_profile.type.name) }}
                </div>

                <!--FEE-->
                <div class="block" v-if="application.fee_amount">
                    <span class="heading mb-3" v-text="$t('fee')"></span>
                    {{application.fee_currency}} {{ application.fee_amount }}
                </div>

                <b-collapse :open="showDetails" class="mb-3">
                    <!--LINKS-->
                    <div class="block" v-if="profileHasLinks">
                        <span class="heading mb-3" v-text="$t('links')"></span>
                        <a target="_blank"
                           :href="artisticProfile.instagram_profile_link"
                           v-if="artisticProfile.instagram_profile_link">
                            <b-icon icon="link"></b-icon> Instagram
                        </a>
                        <a target="_blank"
                           :href="artisticProfile.facebook_profile_link"
                           v-if="artisticProfile.facebook_profile_link">
                            <b-icon icon="link"></b-icon> Facebook
                        </a>
                        <a target="_blank"
                           v-for="link in artisticProfile.links" :key="'profile-' + artisticProfile.id + '-' + link.text"
                           :href="link.href">
                            <b-icon icon="link"></b-icon>
                            {{ link.text ? link.text : link.href }}

                        </a>
                    </div>

                    <!--DESCRIPTION-->
                    <div class="block">
                        <span class="heading mb-3" v-text="$t('description')"></span>
                        {{ artisticProfile.description }}
                    </div>

                    <!--CITY-->
                    <div class="block" v-if="artisticProfile.city">
                        <span class="heading mb-3" v-text="$t('city')"></span>
                        <b-icon icon="map-marker-alt"></b-icon> {{ artisticProfile.city.name}}
                    </div>

                    <!--INSTRUMENTS-->
                    <div class="block">
                        <span class="heading mb-3" v-text="$t('instruments')"></span>
                        <b-taglist class="is-small">
                            <b-tag v-for="instrument in artisticProfile.instruments"
                                   type="is-secondary"
                                   :key="'instrument-' + instrument.id">{{ $t(instrument.name) }}</b-tag>
                        </b-taglist>
                    </div>

                    <!--STYLISTIC OPTIONS-->
                    <div class="block">
                        <span class="heading mb-3" v-text="$t('styles')"></span>
                        <b-taglist>
                            <b-tag v-for="stylisticOption in artisticProfile.stylistic_options"
                                   :key="'stylistic-option-' + stylisticOption.id"
                                   type="is-secondary">
                                {{ $t(stylisticOption.name) }}
                            </b-tag>
                        </b-taglist>
                    </div>

                    <!-- FEATURES -->
                    <div class="block" v-if="artisticProfile.features && artisticProfile.features.length">
                        <span class="heading mb-3" v-text="$t('features')"></span>
                        <b-taglist>
                            <b-tag v-for="feature in artisticProfile.features"
                                   :key="`application-${application.id}-feature-${feature.id}`"
                                   type="is-secondary">
                                {{ $t(feature.type) }}
                            </b-tag>
                        </b-taglist>
                    </div>

                </b-collapse>

                <!--PROFILE DETAILS VISIBILITY TOGGLE-->
                <div class="has-text-centered block">
                    <b-button type="is-text" size="is-small" @click="showDetails = !showDetails">{{ showDetails ? $t('hide details') :$t('details')}}</b-button>
                </div>

            </template><!-- end application details -->

            <keep-alive>
                <chat-thread v-if="chatActive" :application="application"/>
            </keep-alive>

        </div>

        <!--APPLICATION ACTIONS-->
        <div class="card-footer">
            <!--REJECT APPLICATION-->
            <a type="text-is-danger"
               class="card-footer-item"
               @click="reject"
               v-if="'rejected' !== application.status && 'accepted' !== application.status"
               :loading="formBusy">
                <b-icon icon="thumbs-down"></b-icon> {{$t('reject')}}
            </a>
            <!--ACCEPT-->
            <a type="is-success"
               class="card-footer-item"
               @click="accept"
               v-if="'accepted' !== application.status && 'rejected' !== application.status && false"
               :loading="formBusy">
                <b-icon icon="handshake" class="mr-1"></b-icon> {{$t('accept')}}
            </a>
        </div>

    </div>
</template>

<script>
    import {mapActions, mapMutations} from "vuex";
    import formHelperMixin from "@/mixins/formHelperMixin";
    import OrganizerArtisticApplicationsListItemGallery from "./ListItemGallery";
    import {
        ACCEPT_ARTISTIC_APPLICATION,
        REJECT_ARTISTIC_APPLICATION
    } from "@/store/modules/artisticApplications/actions-types";
    import confirmationMessageMixin from "@/mixins/confirmationMessageMixin";
    import ProfileFavoriteCollectionToggle
        from "@/components/Organizer/ArtisticApplications/ProfileFavoriteCollectionToggle";
    import ChatThread from "@/components/Chat/ChatThread";

    export default {
        components: {ChatThread, ProfileFavoriteCollectionToggle, OrganizerArtisticApplicationsListItemGallery},
        props: {
            application: Object,
            index: Number,
        },
        data: function () {
            return {
                showDetails: false,
                chatActive: false,
            };
        },
        methods: {
            ...mapActions('artisticApplications',[
                REJECT_ARTISTIC_APPLICATION,
                ACCEPT_ARTISTIC_APPLICATION
            ]),
            ...mapMutations([
                'updateOrganizerApplicationStatus',
            ]),
            async reject(){
                const {result} = await this.$_p_confirmAction(this.$t('Reject the application?'));
                if (result){
                    this[REJECT_ARTISTIC_APPLICATION](this.application.id)
                        .then(() => {
                            this.$buefy.toast.open(this.$t('application rejected'));
                        });
                }
            },
            async accept(){
                const {result} = await this.$_p_confirmAction(this.$t('Accept the application?'));
                if (result){
                    this[ACCEPT_ARTISTIC_APPLICATION](this.application.id)
                        .then(() => {
                            this.$buefy.toast.open(this.$t('Заявка принята'));
                        });
                }
            },
        },
        computed: {
            artisticProfile(){
                return this.application.artistic_profile;
            },
            types(){
                return this.$store.state.base.applicationTypes;
            },
            profileHasLinks(){
                return this.artisticProfile.instagram_profile_link
                    || this.artisticProfile.facebook_profile_link
                    || this.artisticProfile.links.length;
            },
        },
        mixins: [formHelperMixin, confirmationMessageMixin],
        name: "ArtisticApplicationsListItem"
    };
</script>

<style scoped>

</style>