<template>
    <div class="card-header-icon">
        <b-icon v-if="profile.in_favorite_collection" @click.native="toggleProfileFavorite" icon="star" class="has-text-warning"></b-icon>
        <b-icon v-else icon="star" @click.native="toggleProfileFavorite" pack="far"></b-icon>
    </div>
</template>

<script>
import {TOGGLE_IN_FAVORITE_COLLECTION} from "@/store/modules/artisticProfiles/actions-types";
import {mapActions, mapMutations} from "vuex";
import {TOGGLE_ORGANIZER_APPLICATION_PROFILE_IN_COLLECTION_STATUS} from "@/store/modules/artisticApplications/mutation-types";

export default {
    name: "ProfileFavoriteCollectionToggle",
    props: {
        profile: Object,
        application: Object,
    },
    methods: {
        ...mapActions('artisticProfiles', [
            TOGGLE_IN_FAVORITE_COLLECTION,
        ]),
        ...mapMutations('artisticApplications', [
            TOGGLE_ORGANIZER_APPLICATION_PROFILE_IN_COLLECTION_STATUS,
        ]),
        toggleProfileFavorite(){
            this[TOGGLE_IN_FAVORITE_COLLECTION](this.profile.id)
            .then(res => {
                if (200 === res.status){
                    this[TOGGLE_ORGANIZER_APPLICATION_PROFILE_IN_COLLECTION_STATUS](this.application.id);
                }
            });

        },
    },
};
</script>

<style scoped>

</style>