<template>
    <form action="#" @submit.prevent>
        <h4 class="title is-4">
            <slot name="title">{{$t('Create Organizer profile')}}</slot>
        </h4>

        <cities-list :has-error="form.errors.has('city_id')"
                     :title="$t('Select city')"
                     :error-message="form.errors.get('city_id')"
                     @selected="city => form.city_id = city.id"
                     @clear-error="form.errors.clear('city_id')"
                     :preselected-city-id="organizerProfile.city_id" />

        <b-field :label="$t('Workplace')"
                 :type="{'is-danger': form.errors.has('work_place')}"
                 :message="form.errors.get('work_place')">
            <b-input icon="home"
                     v-model="form.work_place"
                     @keyup.native.enter="createOrganizerProfile"
                     @input="form.errors.clear('work_place')"> </b-input>
        </b-field>

        <b-field :label="$t('Position')"
                 :type="{'is-danger': form.errors.has('position')}"
                 :message="form.errors.get('position')">
            <b-input icon="briefcase"
                     @keyup.native.enter="createOrganizerProfile"
                     v-model="form.position"
                     @input="form.errors.clear('position')"> </b-input>
        </b-field>

        <!--<b-field label="Рабочий телефон">
            <b-input icon="phone" :value="organizerProfile.work_phone"> </b-input>
        </b-field>

        <b-field label="Рабочий имеил">
            <b-input type="email" icon="envelope" :value="organizerProfile.work_email"> </b-input>
        </b-field>-->

        <b-button type="is-primary" @click="createOrganizerProfile" :loading="formBusy">{{$t('Save')}}</b-button>
    </form>

</template>

<script>
    import formHelperMixin from "@/mixins/formHelperMixin";
    import CitiesList from "./parts/common/CitiesList";
    import {mapMutations, mapState} from "vuex";
    import {SET_USER} from "@/store/modules/auth/mutation-types";
    import Form from "vform";

    export default {
        components: {CitiesList},
        name: "CreateOrganizeProfileForm",
        data(){
            return {
                organizerProfile: null,
                form: new Form({
                    city_id: null,
                    work_place: null,
                    position: null,
                }),
            };
        },
        methods:{
            ...mapMutations('auth', [
                SET_USER,
            ]),
            createOrganizerProfile(){
                this.form.post('organizer-profiles')
                    .then(res => {
                        this[SET_USER]({...this.user, ...{organizer_profile: res.data, is_organizer: true}});
                        this.$buefy.toast.open({
                            message: this.$t('Changes saved'),
                            type: 'is-success'
                        });
                    });
            },
            updateOrganizerProfile(){
                if (!this.organizerProfile || !this.organizerProfile.id){
                    this.axios.post('organizer-profiles')
                        .then(res => {
                            if (201 === res.status){
                                this[SET_USER]({...this.user, ...{organizer_profile: res.data, is_organizer: true}});
                                this.organizerProfile = { ...this.organizerProfile, ...res.data};
                            }
                        });
                }
                this.$_p_formPut('organizer-profiles/' + this.organizerProfile.id, this.organizerProfile)
                    .then(res => {
                        this[SET_USER]({...this.user, ...{organizer_profile: res.data, is_organizer: true}});
                        this.$buefy.toast.open({
                            message: this.$t('Changes saved'),
                            type: 'is-success'
                        });
                    });
            },
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
            }),
        },
        created() {
            this.organizerProfile = {...this.user.organizer_profile};
        },
        mixins: [formHelperMixin],
    };
</script>

<style scoped>

</style>